export default [{
    path: "/",
    name: "default",
    meta: {
      title: "Home",
      authRequired: true,
    },
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/embed/:key",
    name: "/embed",
    meta: {
      title: "Home",
      authRequired: true,
    },
    component: () => import("../views/embed/Index.vue"),
  },
  {
    path: "/tes_recombee",
    name: "/tes_recombee",
    meta: {
      title: "tes_recombee",
      authRequired: true,
    },
    component: () => import("../views/tes_recombee/Index.vue"),
  },
  {
    path: "/home",
    name: "/home",
    meta: {
      title: "Home",
      authRequired: true,
    },
    component: () => import("../views/home/index.vue"),
  }, {
    path: "/search_all",
    name: "search_all",
    meta: {
      title: "Search All",
      authRequired: true,
    },
    component: () => import("../views/search_all/index.vue"),
  }, {
    path: "/search_sdg",
    name: "search_sdg",
    meta: {
      title: "Search SDG",
      authRequired: true,
    },
    component: () => import("../views/search_sdg/index.vue"),
  },
  {
    path: "/profiles",
    name: "profiles",
    meta: {
      title: "Profiles",
      authRequired: true,
    },
    component: () => import("../views/profiles/index.vue"),
  },
  {
    path: "/profiles_detail/:autor",
    name: "profiles_detail",
    meta: {
      title: "Profiles Detail",
      authRequired: true,
    },
    component: () => import("../views/profiles_detail/index.vue"),
    children: [
      {
        path: "/profiles_detail/overview/:autor",
        name: "profiles_detail_overview",
        meta: {
          title: "Profiles Detail",
          authRequired: true,
        },
        component: () => import("../views/profiles_detail/component/OverView.vue"), 
      },
    ]
  },
  {
    path: "/paper",
    name: "paper",
    meta: {
      title: "Paper",
      authRequired: true,
    },
    component: () => import("../views/paper/index.vue"),
  },
  {
    path: "/thesis",
    name: "thesis",
    meta: {
      title: "Thesis",
      authRequired: true,
    },
    component: () => import("../views/thesis/index.vue"),
  },
  {
    path: "/thesis_detail/:title",
    name: "thesis_detail",
    meta: {
      title: "thesis_detail",
      authRequired: true,
    },
    component: () => import("../views/thesis_detail/index.vue"),
  },
  {
    path: "/paper_contry/:univ/:contry",
    name: "paper_contry",
    meta: {
      title: "Paper Collaborations and top research",
      authRequired: true,
    },
    component: () => import("../views/paper_contry/index.vue"),
  },
  {
    path: "/paper_detail/:title",
    meta: {
      title: "Paper Detail",
      authRequired: true,
    },
    component: () => import("../views/paper_detail/index.vue"),

  },
  {
    path: "/project",
    name: "project",
    meta: {
      title: "Project",
      authRequired: true,
    },
    component: () => import("../views/project/index.vue"),
  }, {
    path: "/project_detail/:title",
    name: "project_detail",
    meta: {
      title: "Project Detail",
      authRequired: true,
    },
    component: () => import("../views/project_detail/index.vue"),
  },
  {
    path: "/patent",
    name: "patent",
    meta: {
      title: "Paten",
      authRequired: true,
    },
    component: () => import("../views/paten/index.vue"),
  },
  {
    path: "/paten_detail/:title",
    name: "paten_detail",
    meta: {
      title: "Paten Detail",
      authRequired: true,
    },
    component: () => import("../views/paten_detail/index.vue"),
  },



  {
    path: "/outreach",
    name: "outreach",
    meta: {
      title: "Outreach",
      authRequired: true,
    },
    component: () => import("../views/outreach/index.vue"),
  },
  {
    path: "/outreach_map",
    name: "outreach_map",
    meta: {
      title: "outreach_map",
      authRequired: true,
    },
    component: () => import("../views/outreach_map/index.vue"),
  },
  {
    path: "/outreach_detail/:title",
    name: "outreach_detail",
    meta: {
      title: "Outreach Detail",
      authRequired: true,
    },
    component: () => import("../views/outreach_detail/index.vue"),
  },


  {
    path: "/equipment",
    name: "equipment",
    meta: {
      title: "Equipment",
      authRequired: true,
    },
    component: () => import("../views/equipment/index.vue"),
  }, 
  {
    path: "/equipment_detail/:title",
    name: "equipment_detail",
    meta: {
      title: "Equipment Detail",
      authRequired: true,
    },
    component: () => import("../views/equipment_detail/index.vue"),
  },
  
  // organization
  {
    path: "/organization",
    name: "organization",
    meta: {
      title: "Organization",
      authRequired: true,
    },
    component: () => import("../views/organization/index.vue"),
  }, 
  {
    path: "/organization_detail/:title",
    name: "organization_detail",
    meta: {
      title: "Organization Detail",
      authRequired: true,
    },
    component: () => import("../views/organization_detail/index.vue"),
  },

  // {
  //   path: "/alasql",
  //   name: "alasql",
  //   meta: {
  //     title: "landing",
  //     authRequired: true,
  //   },
  //   component: () => import("../views/ujicoba/alasql.vue"),
  // },
  {
    path: '/:catchAll(.*)',
    name: "err404",
    meta: {
      title: "Page Not Fond",
      authRequired: true,
    },
    component: () => import("../views/error/err404.vue")
  },

  //Admin Panel

  {
    path: "/register/",
    name: "register",
    meta: {
      title: "Registrasi",
      authRequired: true,
    },
    component: () => import("../views/admin/Registrasi.vue"),
  },
  {
    path: "/register_confirm/",
    name: "register_confirm",
    meta: {
      title: "Registrasi Confirmation",
      authRequired: true,
    },
    component: () => import("../views/admin/RegistrasiConfirmation.vue"),
  },
  {
    path: "/login/",
    name: "login",
    meta: {
      title: "Login App",
      authRequired: true,
    },
    component: () => import("../views/admin/Login.vue"),
  }, 
  {
    path: "/auth/",
    name: "auth",
    meta: {
      title: "Admin Panel",
      authRequired: true,
    },
    component: () => import("../views/admin/Auth.vue"),
  },
  {
    path: "/auth/google/callback",
    name: "callback",
    meta: {
      title: "Admin Panel",
      authRequired: true,
    },
    component: () => import("../views/admin/Callback.vue"),
  },
  {
    path: "/verify/:token",
    name: "callback",
    meta: {
      title: "Admin Panel",
      authRequired: true,
    },
    component: () => import("../views/admin/Verify.vue"),
  },
  {
    path: "/member_area",
    name: "member_area",
    meta: {
      title: "Member Araa",
      authRequired: true,
    },
    component: () => import("../views/member_area/index.vue"),
    children: [
      {
        path: "/overview",
        name: "overview",
        meta: {
          title: "Overview Member Area",
          authRequired: true,
        },
        component: () => import("../views/member_area/overview/index.vue"),
       
      },
      {
        path: "/discuss",
        name: "discuss",
        meta: {
          title: "Discuss Member Area",
          authRequired: true,
        },
        component: () => import("../views/member_area/discuss/index.vue"),
       
      }, {
        path: "/follow",
        name: "follow",
        meta: {
          title: "Follow Member Area",
          authRequired: true,
        },
        component: () => import("../views/member_area/follow/index.vue"),
       
      }, {
        path: "/love",
        name: "love",
        meta: {
          title: "Love Member Area",
          authRequired: true,
        },
        component: () => import("../views/member_area/love/index.vue"),
       
      }, {
        path: "/tag",
        name: "tag",
        meta: {
          title: "Tag Member Area",
          authRequired: true,
        },
        component: () => import("../views/member_area/tag/index.vue"),
       
      }, {
        path: "/edit_profile",
        name: "edit_profile",
        meta: {
          title: "Edit Profile",
          authRequired: true,
        },
        component: () => import("../views/member_area/edit_profile/index.vue"),
       
      },
    ]
  },
];