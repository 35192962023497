import {
    createApp
} from 'vue';
import App from './App.vue';
import router from "./router";
import VueRouter from 'vue-router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./store"; 
import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import {
    vMaska
} from "maska"
import VueFeather from 'vue-feather';
import '@/assets/scss/config/material/app.scss';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
// import 'jsvectormap/dist/jsvectormap.css';
 
// import TypesenseClient from 'typesense';

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

import mixpanel from '@/config/mixpanel';
import './config/typesenseService'
import vue3GoogleLogin from 'vue3-google-login'

// let gauthClientId = "747986017652-m8set9cf6jli2thf6phi47chu20u5rqa.apps.googleusercontent.com";

// import gAuthPlugin from 'vue3-google-oauth2'; 
// let gauthClientId = "747986017652-m8set9cf6jli2thf6phi47chu20u5rqa.apps.googleusercontent.com";
 

// Memuat script eksternal
const script = document.createElement('script');
script.src = "https://cdn.jsdelivr.net/gh/recombee/js-api-client/dist/recombee-api-client.min.js";
script.async = true;
document.head.appendChild(script);


createApp(App)
    .use(vue3GoogleLogin, {
        clientId: '747986017652-m8set9cf6jli2thf6phi47chu20u5rqa.apps.googleusercontent.com'
    }) 
    //  .use(gAuthPlugin, { clientId: gauthClientId, scope: 'email', prompt: 'consent', fetch_basic_profile: false })
    .use(store)
    .use(mixpanel)
    .use(router)
    .use(VueRouter)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .component(VueFeather.type, VueFeather)
    .directive("maska", vMaska)
    .use(i18n)
    .use(vClickOutside).mount('#app');