<script>
import footer_app from './components/footer.vue';
import navBar from './components/NavBar.vue';
export default {
  name: 'App',
  data() {
    return {

    };
  },
  components: {
    footer_app,
    navBar
  },
  methods: {
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.setActiveSection);
  },
  mounted() {
    console.log("Base URL:", process.env.VUE_APP_BASE_URL);
    console.log("Environment:", process.env.VUE_APP_ENV);
    window.addEventListener('scroll', this.setActiveSection);
    let backtoTop = document.getElementById("back-to-top");

    if (backtoTop) {
      backtoTop = document.getElementById("back-to-top");
      window.onscroll = function () {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
          backtoTop.style.display = "block";
        } else {
          backtoTop.style.display = "none";
        }
      };
    }

    window.addEventListener('scroll', function (ev) {
      ev.preventDefault();
      var navbar = document.getElementById("navbar");
      if (navbar) {
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
          navbar.classList.add("is-sticky");
        } else {
          navbar.classList.remove("is-sticky");
        }
      }
    });


  },
};
</script>

<template>
  <div class="layout-wrapper landing">
    <nav-bar></nav-bar>
    <router-view></router-view>
    <footer_app></footer_app>
    <BButton variant="danger" @click="topFunction" class="btn-icon landing-back-top" id="back-to-top">
      <i class="ri-arrow-up-line"></i>
    </BButton>
  </div>
</template>